<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => { }
    }
  },
  render() {
    return (
      <div class="tag-name">
        <span style={{ background: this.data.color }} />
        <span>{this.data.name}</span>
      </div>
    )
  }
}
</script>
<style lang="less" scoped>
.tag-name {
  display: flex;
  align-items: center;
  justify-content: center;
  & > span:first-child {
    width: 5px;
    height: 5px;
    margin-right: 3px;
    border-radius: 50%;
  }
}
</style>