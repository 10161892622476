<script>
import TemplateHeaderTable from '@/components/TemplateHeaderTable'
import { downloadAction, getAction } from '../../command/netTool'
import TagName from '@/components/TagName'
export default {
  data() {
    return {
      // 乡镇数据
      townsData: [],
      // 冷库状态
      storage_status: '',
      // 当前电量
      totalPower: 0,
      // 全部电量
      totalPower: 0,
    }
  },
  mounted() {
    // 获取街道数据
    getAction('/api/currency/region/list/330282').then(
      (e) => (this.townsData = e.map((e) => ({ name: e.name, value: e.id })))
    )
    // 获取字典数据
    getAction('/api/admin/dict/code/storage_status').then(
      (e) => (this.storage_status = e.map((e) => ({ name: e.name, value: e.id })))
    )
    // 获取耗电统计
    getAction('/api/gxlkoperation/report/powerTotal').then((e) => {
      this.currentPower = e.currentPower
      this.totalPower = e.totalPower
    })
  },
  methods: {
    getHeader() {
      return [
        {
          name: '乡镇',
          type: 'select',
          key: 'streetCode',
          typeData: this.townsData,
        },
        {
          name: '冷库类型',
          type: 'select',
          key: 'storageType',
          typeData: [
            {
              name: '保鲜',
              value: '保鲜',
            },
            {
              name: '冷藏',
              value: '冷藏',
            },
            {
              name: '冷冻',
              value: '冷冻',
            },
          ],
        },
        {
          name: '冷库状态',
          type: 'select',
          key: 'status',
          typeData: [
            {
              name: '全部',
              value: '',
            },
            ...this.storage_status,
          ],
        },
        {
          name: '筛选时间',
          type: 'rangePicker',
          keys: ['startDate', 'endDate'],
        },
      ]
    },
    getButton() {
      return [
        {
          name: '导出excel',
          type: 'primary',
          onClick: () => {
            downloadAction('/api/gxlkoperation/report/powerPageDownload', '耗电统计.xlsx')
          },
        },
      ]
    },
    getColumns() {
      return [
        {
          title: '所在乡镇',
          dataIndex: 'streetName',
        },
        {
          title: '冷库简称',
          dataIndex: 'storageName',
        },
        {
          title: '冷库类型',
          dataIndex: 'storageType',
        },
        {
          title: '库主及手机号',
          dataIndex: 'storageOwner',
          render: ({ records }) => {
            return (
              <span>
                {records.ownerName}
                {records.ownerPhone}
              </span>
            )
          },
        },
        {
          title: '冷库状态',
          dataIndex: 'status',
          render: ({ text }) => {
            const data = {
              0: {
                name: '未共享',
                color: 'red',
              },
              1: {
                name: '共享中',
                color: 'green',
              },
              2: {
                name: '已关闭',
                color: 'gray',
              },
            }[text]
            return <TagName data={data} />
          },
        },
        {
          title: '耗电量-kwh',
          dataIndex: 'power',
          sorter: true,
        },
      ]
    },
    renderFoot() {
      const data = [
        {
          name: '总计',
          value: this.totalPower,
        },
        {
          name: '本页小计',
          value: this.currentPower,
        },
      ]
      return (
        <div class="power">
          {data.map((e) => {
            return (
              <div>
                <span>{e.name}:</span>
                <span>{e.value}</span>
              </div>
            )
          })}
        </div>
      )
    },
  },
  render() {
    return (
      <TemplateHeaderTable
        url={'/api/gxlkoperation/report/powerPage'}
        tableColumns={this.getColumns()}
        headerData={this.getHeader()}
        button={this.getButton()}
        tableProps={{
          showFootButton: true,
          foot: this.renderFoot,
        }}
      />
    )
  },
}
</script>
<style lang="less" scoped>
.power {
  display: flex;
  align-items: center;
  & > div > span:nth-child(2) {
    color: red;
  }
}
</style>
